<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:24:13
 * @LastEditTime: 2023-06-30 18:49
 * @Descripttion: 日志中心
-->
<style lang="scss" scoped>
.daily-record {
    @include innerPage(56px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 46px), 30px);
    @include pageFoot;
}
</style>

<template>
    <section class="daily-record">
        <div class="page-title">
            <h3>日志中心</h3>
            <el-button type="custom_primary" size="medium" @click="exportFile" :disabled="btnLoad" v-loading="btnLoad">
                导出<i class="iconfont">&#xe62d;</i>
            </el-button>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="auser_nickname" label="操作人" min-width="16%" />
                    <el-table-column align="center" prop="alog_ip" label="IP" min-width="14%" />
                    <el-table-column align="center" label="类型" min-width="16%">
                        <template slot-scope="scope">
                            {{ scope.row.alog_type == 1 && '登录' || scope.row.alog_type == 2 && '操作' || '系统运行监控' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="alog_model" label="操作模型" min-width="12%" />
                    <el-table-column align="center" label="请求类型" min-width="12%">
                        <template slot-scope="scope">
                            {{ scope.row.alog_reqtype == 1 && 'POST' || 'GET' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="alog_url" label="请求地址" min-width="16%" />
                    <el-table-column align="center" prop="create_time" label="操作时间" min-width="14%">
                        <template slot-scope="scope">
                            {{ scope.row.create_time * 1000 | formatTime }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
    </section>
</template>

<script>
import { $operationLog, $exportLog } from "@api/common"
import customPagination from "@comp/customPagination"

export default {
    name: 'dailyRecord',
    components: {
        customPagination
    },
    data() {
        return {
            tableData: [],
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                total: 0
            },
            btnLoad: false,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取日志列表 */
        async getData() {
            let { data: res } = await $operationLog(this.tablePage.pageIndex);
            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /** 切换页码 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /**导出 */
        async exportFile() {
            this.btnLoad = true;
            let { data } = await $exportLog();
            this.btnLoad = false;
            this.$fileDownload(data.full_file_url);
        }
    },
}
</script>